<template>
  <div class="servico">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 mt-5">
          <lista
            v-if="$store.getters.getUser.profile == 3 && service.type != 5"
            :list.sync="list"
          />
        </div>
        <div class="col-md-8 mt-5" id="frames">
          <iframe_list
            :service="service"
            :categoryName="categoryName"
            :score="score"
            :totalPages.sync="totalPages"
            :list.sync="list"
            :instructions="instructions"
            :link="service.videoUrl"
          />
        </div>
        <div class="col-md-2 pr-0">
          <instrucao
            :instructions="instructions"
            :link="service.videoUrl"
            v-if="homologa"
          />
        </div>
        <modalEdit
          :service="service"
          v-if="$store.getters.getUser.profile != 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import lista from "@/components/servicos/interno/_ListaImpressora";
import iframe_list from "@/components/servicos/interno/_Iframe";
import instrucao from "@/components/servicos/interno/_Instrucao";
import modalEdit from "@/components/utils/solicitar_servicoEdit.vue";

export default {
  props: ["service", "instructions", "categoryName", "score"],

  components: {
    lista,
    iframe_list,
    instrucao,
    modalEdit
  },
  data() {
    return {
      totalPages: 0,
      list: [],
      homologa: true
    };
  },
  mounted() {
    this.homologaShow();
  },
  watch: {
    list: function() {
      var total = this.list.reduce((total, item) => total + item.quantity, 0);
      this.totalPages = total;
    }
  },
  computed: {
    totalMetas() {
      return this.$store.getters.totalMetas;
    }
  },
  methods: {
    homologaShow() {
      let urlApi = process.env.VUE_APP_ROOT_API;

      if (urlApi === "//apiteste.pontofacil.fasters.com.br") {
        this.homologa = false;
      }
    }
  }
};
</script>

<style scoped>
.servico {
  background-color: #2474b2;
}
</style>
