<template>
  <div>
    <div
      class="modal fade"
      id="addCodigo"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content backgroundColorDefault">
          <div class="modal-header headerModal">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style="color: #ccc">X</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="form-group col-12 p-0">
                  <label for="cod">
                    <h3>Código de Barras ( Use o Leitor ou Digite )</h3>
                  </label>
                  <the-mask
                    type="text"
                    class="form-control mb-3"
                    :mask="[
                      '#####.##### #####.###### #####.###### # ###############'
                    ]"
                    v-model="codigo"
                  />
                  <b class="textRequired mt-2" v-if="requerid"
                    >Campo Obrigatorio</b
                  >
                </div>
              </div>
            </div>
            <div class="container d-flex justify-content-end">
              <div class="row">
                <button
                  type="button"
                  class="btn btn_default pl-5 pr-5 text-center mr-3"
                  @click="validationCodigo"
                >
                  <loader color="#e0a638" size="20" v-if="load" />
                  <h6 class="m-0" v-else>Adicionar</h6>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { API } from "@/api_financial";
import { mapGetters } from "vuex";
import loader from "@/components/utils/_Load";
export default {
  components: { TheMask, loader },
  data() {
    return {
      // codigo: '82620.00000 22546.300191 00612.219306 4 294131216005424',
      // 836100000022205601380004579272821110080311191292
      codigo: '',
      requerid: false,
      load: false
    };
  },
  computed: {
     ...mapGetters({
		  list: 'dataContasGet',
      codigoConsulta: 'codigoResponseData',
      debitos: 'dataDebitosGet'
	  }),
    date() {
      let data = new Date();
      let dia = String(data.getDate()).padStart(2, "0");
      let mes = String(data.getMonth() + 1).padStart(2, "0");
      let ano = data.getFullYear();
      // return  `${dia}-${mes}-${ano}`
      return `${ano}-${mes}-${dia}`;
    }
  },
  methods: {
    async validationCodigo() {
      this.load = true

      if(this.codigo) {
      
       try {
         let { data } = await API.get(`consultas/${this.codigoConsulta}/validar/${this.codigo}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getTokenZigNet}`,
            "Content-Type": "application/json"
          }
        })

         let { detalhe } = data
         let { codigo, list, debitos } = this
         let detalhes = { ...detalhe, codigo }

        let json = {
          barras: detalhes.codigo,
          descricao: detalhes.emissor,
          vencimento: detalhes.validade ? detalhes.validade : this.date,
          valor: detalhes.valor
        }

        let result = await API.post(`consultas/${this.codigoConsulta}/debitos`, json, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getTokenZigNet}`,
            "Content-Type": "application/json"
          }
        })

        let jsonDebitos = {
          "codigo": result.data.codigo,
          "valor": detalhes.valor
        }

        if(!result.data.sucesso) {
          this.$toast.error(result.data.mensagem)
          this.load = false
          return
        }

         if(list) {

           let jsonAllDebitos = [...debitos, jsonDebitos]
           let listAll = [...list, detalhes]

           this.$store.commit('dataContasNewValue', listAll)
           this.$store.commit('dataDebitosNewValue', jsonAllDebitos)
           
         } else {
           
           this.$store.commit('dataContasNewValue', [detalhes])
           this.$store.commit('dataDebitosNewValue', [jsonDebitos])

         }
         this.toast('Boleto adicionando com Sucesso')

          $("#addCodigo").modal("hide");

          this.load = false

          this.codigo = ''

       } catch (error) {
         
         this.$toast.error('Erro ao inserir Boleto')
         
         console.log(error)
         this.load = false
       }

          console.log(error);
          this.load = false;
        }
       else {
        this.requerid = true;
        this.load = false;
      }
    },
     toast(msn) {
      	this.$toast(msn, {
            position: "bottom-right",
            timeout: 4000,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: true,
            hideProgressBar: false,
            closeButton: 'button',
            icon: true,
            rtl: false
        })
    },
    toast(msn) {
      this.$toast(msn, {
        position: "bottom-right",
        timeout: 4000,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    }
  }
};
</script>

<style scoped>
.headerModal {
  border: 0 !important;
}

.backgroundColorDefault {
  background-color: #fff;
  color: #2474b2;
  border-radius: 20px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}

h3 {
  font-weight: 700;
  font-size: 15px;
}

.row {
  padding: 20px;
  margin-bottom: 1px solid #ccc;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
}

.btn_default:hover {
  background-color: #e0a638;
}

b {
  color: #f38235;
  margin: 10px 0;
}
</style>
