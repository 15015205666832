<template>
  <div>
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content backgroundColor">
          <div class="modal-body  rounded border-0">
            <div class="container">
              <div class="row m-3">
                <h3 class="text-center">
                  Tem certeza que deseja excluir essa conta da Lista?
                </h3>
              </div>

              <div class="d-flex justify-content-between">
                <button
                  type="button"
                  class="btn btn_default pl-4 pr-4"
                  data-dismiss="modal"
                >
                  Não! Voltar
                </button>
                <button
                  type="button"
                  class="btn btn_default pl-5 pr-5"
                  data-dismiss="modal"
                  @click="deleteItens"
                >
                  sim
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    deleteItens() {
      this.$store.dispatch('deleteDataContas', this.item)
    },
  }
}
</script>

<style scoped>
.backgroundColor {
  background-color: #fff;
  color: #308cd3;
  border-radius: 20px;
}

h3 {

  font-weight: 700;
  font-size: 15px;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  width: auto;
  padding: 5px;
  /* margin: 10px 0 5px 5px; */
}

.btn_default:hover {
  background-color: #e0a638;
}
</style>