<template>
  <div
    class="container d-flex justify-content-center containerResume flex-column"
  >
    <h1 class="text-center mb-4">Resumo</h1>

    <div>
      <h2>Valor Total:</h2>
      <p class="ml-3 mb-3 mt-3">{{ valueConvert(value) }}</p>
      <h2>Condição:</h2>
      <p class="ml-3 mb-3 mt-3">{{ parcela.parcela }}</p>
    </div>

    <div>
      <h2>Quer Pagar como?</h2>

      <div v-for="itens in inputRadiosCheck" :key="itens.id" >
        <div class="form-check flex inputs" v-if="!itens.disabled">
          <input
            class="form-check-input checkInput"
            type="radio"
            name="flexRadioDefault"
            :id="itens.id"
            :value="itens"
            v-model="valueInput"
          />
          <label class="form-check-label" :for="itens.id">
            {{ itens.text }}
          </label>
        </div>
      </div>
    </div>
    
    <div class="container">
      <div class="row text-center d-flex justify-content-around">
        <button
          class="btn btn_default pl-4 pr-4"
          @click="conectPiyPad"
        >
          Confirmar
        </button>
        <button class="btn btn_default pl-4 pr-4" @click="componenteChangeStore('List')">
          Voltar
        </button>
      </div>
    </div>
    <Messagemodal :conditions='valueSocket' />
  </div>
</template>

<script>
import Messagemodal from "../Modal/messageModal.vue";
import { mapGetters } from 'vuex'
export default {
  components: { Messagemodal },
  data() {
    return {
      inputRadiosCheck: [
        {
          id: 'debito',
          text: 'Cartão de débito (Apenas à vista)',
          disabled: false,
          type: 2
        },
        {
          id: 'credito',
          text: ' 01 Cartão de crédito',
          disabled: false,
          type: 3
        },
        {
          id: 'credito+',
          text: '+ de 01 cartão de crédito (Até 4)',
          disabled: false,
          type: 3
        },
        // {
        //   id: 'terceiros',
        //   text: ' Cartão de crédito de terceiros',
        //   disabled: false,
        //   type: 3
        // },
      ],
      valueInput: undefined,
      socket: null,
      valueSocket: null
    }
  },
  computed: {
    ...mapGetters({
    value: 'valueParcelamentoContasGet',
    parcela: 'parcelaXGet',
    ConsultNumber: 'codigoResponseData',
    userNameOperator: 'getUser',
  })
  },
  methods: {
    firstText(params) {
      return params.split(' ')[0]
    },

    connection() {
      this.socket = new WebSocket("ws://127.0.0.1:9696/Spf");

      this.socket.onopen = (e) => {
        console.log('conectado')
        this.valueSocket = 'conectado'
      };

      this.socket.onmessage = ({data}) => {
        this.valueSocket = data
        $('#Messagemodal').modal('show')
        console.log(this.valueSocket)
      };

      this.socket.onerror = function(error) {
        console.log(`[error] ${error.message}`);
      };
    },

    conectPiyPad() {
      const { value, parcela, ConsultNumber, userNameOperator, valueInput } = this 
      if (valueInput.id === 'credito+') {
        $('#Messagemodal').modal('show')
        return
      }
      
      let removeString = parcela.parcela.substr(-24, 6).replace(',', '.')
      let valueCetAll = Number(removeString)
      const Installments = this.firstText(parcela.parcela)
      const nameUserOperator = this.firstText(userNameOperator.name)
      const convertConsultNumberString = String(ConsultNumber)
      const jsonResumoPay = {
        ValueTotal: valueCetAll,
        ConsultNumber: convertConsultNumberString,
        NameOperator : nameUserOperator,
        Payments : [
          {
              "Installments": Installments,
              "TypeCard": valueInput.type,
              "Value": valueCetAll
          }
        ]
      }
      // console.log(valueInput.id)
      // return
      let data = {
        "tipo": valueInput.type,
        "parcelas": Installments,
        "valor": value,
        "terminal": "4030707035",
      }

      this.$store.commit('dataPayNewValue', data)
      this.$store.dispatch('conectPinPadPay', jsonResumoPay)
      
      this.connection()

      // $('#Messagemodal').modal('show')

    }
  },
};
</script>

<style scoped>
.containerResume {
  color: #fff;
  width: 400px;
  margin: 50px auto 10px;
}

h1 {
  font-size: 25px;
}
h2 {
  font-size: 15px;
}
.checkInput {
  margin-top: 0 !important;
}

.inputs {
  display: flex;
  /* align-items: center; */
  margin: 20px;
  cursor: pointer;
}

.inputs label {
  cursor: pointer;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  width: 40%;
  margin: 10px 0 5px 5px;
}

.btn_default:hover {
  background-color: #e0a638;
}
</style>