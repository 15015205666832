<template>
  <div>
    <div
      class="modal fade"
      id="changeValues"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content backgroundColorDefault">
          <div class="modal-header headerModal">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style='color:#ccc'>X</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <h3>Quer Alterar o Valor á Pagar?</h3>
              <div class="flex">
                <div>
                  <h3>Mínino: {{ valueConvert(valorMin) }}</h3>
                  <h3>Maximo: {{ valueConvert(valorMax) }}</h3>
                </div>
                <div>
                  <money v-bind="money" class="inputValueMoney"></money>
                </div>
              </div>
            </div>

            <div class="row">
              <h3>Quer Alterar a Data do Vencimento?</h3>
              <div class="flex">
                <div>
                  <h3>Mínino: {{ dateMin }}</h3>
                  <h3>Maximo: {{ dateMax }}</h3>
                </div>
                <div>
                  <input class="inputValueMoney" type="date" />
                </div>
              </div>
            </div>
          <div class="container d-flex justify-content-end">
            <div class="row">
              <button
                type="button"
                class="btn btn_default pl-5 pr-5 text-center mr-3"
                data-dismiss="modal"
                aria-label="Close"
                @click="componenteChangeStore('List')"
              >
                ok
              </button>
            </div>
          </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";

export default {
  components: {
    Money,
  },
  data() {
    return {
      valorMin: 20,
      valorMax: 40,
      dateMin: "10 / 10 / 2021",
      dateMax: "20/12/2021",
      money: {
        value: '50',
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
};
</script>

<style scoped>
.headerModal {
  border: 0 !important;
}

.backgroundColorDefault {
  background-color:  #fff;
  color: #2474b2;
  border-radius: 20px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}

h3 {
  font-weight: 700;
  font-size: 15px;
}

.row {
  padding: 20px;
  margin-bottom: 1px solid #ccc;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  /* margin: 10px 0 5px 5px; */
}

.btn_default:hover {
  background-color: #e0a638;
}

.inputValueMoney {
  height: 40px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  /* border: none; */
      border: 2px solid #e3e3e3;
    /* border-radius: 20px; */
    background-color: #f6f6f6;
      font-weight: 700;
  color: #2474b2;

}
</style>