<template>
  <div>
    <Top />
    <transition name="vertical">
      <component :is="cpComponet" />
    </transition>

    <Modal />
  </div>
</template>

<script>
import Top from "./top.vue";
import Users from "./Users/users.vue";
import Detran from "./Users/Detran.vue";
import Modal from "./Modal/changeValue.vue";
import List from "./ListContainer/list.vue";
import Resume from "./ListContainer/resume.vue";

export default {
  components: {
    Top,
    Users,
    Modal,
    List,
    Resume,
    Detran
  },
  computed: {
    cpComponet() {
      return this.$store.state.installment.component;
    }
  },
  mounted() {
    this.cp = this.$store.getters.componentCurrentValue;
  },
};
</script>

<style scoped>
.containerInstallment {
  color: #fff;
}
.containerInstallment h1 {
  font-size: 20px;
  margin: 15px 0 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.checkInput {
  margin-top: 0 !important;
}

.sizeContainer {
  width: 30%;
}

.formBtn {
  display: flex;
  justify-content: flex-end;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  width: 20%;
  margin: 10px 0 5px 5px;
}

.btn_default:hover {
  background-color: #e0a638;
}
</style>
