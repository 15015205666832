<template>
  <div class="top">
    <h1 class="titleComfirm" v-if="payConfirm">Pagamento(s) Confirmados</h1>
    <div class="container">
      <div class="row titles" v-if="list.length > 0">
        <div class="col-6 text-center">
          {{
            $route.params.id === "Contas" ? "Código de Barras" : "Modalidade"
          }}
        </div>
        <div class="col-2 text-center">
          {{ $route.params.id === "Contas" ? "Beneficiário" : "Vigência" }}
        </div>
        <div class="col-2 text-center">Valor</div>
        <div class="col-2 text-center" v-if="payConfirm">Status</div>
      </div>
    </div>
    <div class="container mt-3 containerDad" v-if="list.length > 0">
      <div
        v-for="(item, index) in list"
        :class="
          list.length === 1 ? 'row containerList radius' : 'row containerList'
        "
        :key="index"
      >
        <div class="col-6 text-center">
          <h3>
            {{ $route.params.id === "Contas" ? item.codigo : item.modalidade }}
          </h3>
        </div>
        <div class="col-2 text-center">
          <h3>
            {{ $route.params.id === "Contas" ? item.emissor : item.vigencia }}
          </h3>
        </div>
        <div class="col-2 text-center">
          <h3>{{ valueConvert(item.valor) }}</h3>
        </div>

        <div
          class="col-2 d-flex justify-content-center text-center"
          v-if="payConfirm"
        >
          <img
            style="width: 20px"
            src="@/assets/image/icones/new/check.png"
            alt="busca"
            v-if="success"
          />
          <img
            v-else
            style="width: 20px"
            src="@/assets/image/icones/new/close.png"
            alt="busca"
          />
          <button
            class="btn btn-icons"
            data-toggle="modal"
            data-target="#detalhesModal"
          >
            <img
              style="width: 20px"
              src="@/assets/image/icones/new/search.svg"
              alt="busca"
            />
          </button>
        </div>

        <div class="col text-center" v-else>
          <button
            class="btn btn-icons"
            data-toggle="modal"
            @click="itemDetalhesCurrent(item, false)"
          >
            <img
              style="width: 20px"
              src="@/assets/image/icones/new/search.svg"
              alt="busca"
            />
          </button>
          <button
            class="btn btn-icons"
            data-toggle="modal"
            data-target="#deleteModal"
            @click="itemDetalhesCurrent(item, true)"
          >
            <img src="@/assets/image/icones/new/delete.svg" alt="deletar" />
          </button>
        </div>
      </div>
    </div>

    <div class="container mt-3 containerDad" v-else>
      <h1 class="null">Nenhum Boleto adicionado</h1>
    </div>
    
    <Delete :item="currentDetalhe" />

    <Detail :detalhes="currentDetalhe" />

    <Boleto />

    <Messagemodal :conditions="conditionsModal" />

    <Add :pay="payConfirm" @email="conditions" :soma="amount" />

    <!-- <Pay :parcelamento="parcelamento" v-if="!payConfirm && list.length > 0" /> -->

    <div class="pay" v-if="!payConfirm && list.length > 0">
      <v-select
        class="payColor"
        v-model="selectPay"
        label="parcela"
        placeholder="Selecione modo de pagamento"
        :options="parcelamento"
      >
      </v-select>
    </div>

    <Buttons v-if="payConfirm && list.length > 0" @finish="conditions" />

    <div class="text-right" v-if="!payConfirm && list.length > 0">
      <button class="btn btn_default pl-5 pr-5" @click="debitos">
        Seguinte
      </button>
    </div>
  </div>
</template>

<script>
import Messagemodal from "../Modal/messageModal.vue";
// import Pay from "./selectPay.vue";
import Add from "./add.vue";
import Delete from "../Modal/deleteAccount.vue";
import Detail from "../Modal/detailAccount.vue";
import Buttons from "./buttons.vue";
import Boleto from "../Modal/addBoleto.vue";
import { API } from "@/api_financial";
import { mapGetters } from "vuex";

export default {
  components: { Add, Delete, Detail, Buttons, Messagemodal, Boleto },
  data() {
    return {
      list: [],
      amount: 0,
      parcelamento: [],
      payConfirm: false,
      success: false,
      conditionsModal: 0,
      currentDetalhe: [],
      selectPay: null,
    };
  },
  computed: {
    ...mapGetters({
      listDetalhes: "dataContasGet",
      codigoConsulta: "codigoResponseData"
    })
  },
  mounted() {
    if (this.listDetalhes.length > 0) {
      this.list = this.listDetalhes;
      this.getParcelas();
      this.soma();
    }
  },
  methods: {
    itemDetalhesCurrent(item, deleteItens) {
      this.currentDetalhe = item;
      if (deleteItens) return;
      $("#detalhesModal").modal("show");
    },

    componenteChange() {
      this.$store.commit("changeComponent", "Resume");
    },

    debitos() {
      let { selectPay, list } = this;
      if (selectPay && list.length > 0) {
        this.componenteChangeStore("Resume");
        return;
      }

      this.$toast.error("Boleto e parcelas deve ser adicionandos");
    },

    conditions(value) {
      console.log(value);
      this.conditionsModal = value;
    },

    getValues() {
      API.get(`consultas/${this.codigoConsulta}/debitos`, {
        headers: {
          Authorization: `Bearer ${ this.$store.getters.getTokenZigNet}`,
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          // console.log(res);
        })
        .catch(err => console.log(err));
    },
    getParcelas(result) {
      API.get(`consultas/${this.codigoConsulta}/parcelas?valor=${result}`, {
        headers: {
          Authorization: `Bearer ${ this.$store.getters.getTokenZigNet}`,
          "Content-Type": "application/json"
        }
      })
        .then(({ data }) => {
          let parcela = data.parcelas.map(item => {
            return { parcela: `${item.parcelas} parcelas de ${item.valor} - valor total ${this.valueConvert(item.total)} - CET ${this.valueConvert(item.cet)} - ${item.tipo}` } // formatar valores
          })

          this.parcelamento = parcela;
        })
        .catch(err => console.log(err));
    },

    soma() {
      let valuesNamber = [];

      this.list.forEach(item => {
        valuesNamber.push(item.valor);
      });

      let result = valuesNamber.reduce((amount, currentValue) => {
        return amount + currentValue;
      }, 0);

      this.amount = result;
      this.$store.commit("valueParcelamentoContas", this.amount);
      this.getParcelas(result);
      return result;
    }
  },
  watch: {
    listDetalhes: function(value) {
      this.list = value;
      this.soma();
    },
    selectPay: function(value) {
      this.$store.commit("parcelaX", value);
    }
  }
};
</script>

<style scoped>
.top {
  margin-top: 100px;
}

.titleComfirm {
  color: #fff;
  text-align: center;
  font-size: 25px;
  margin: 40px 0;
}

.titles {
  color: rgb(255, 255, 255);
}

h3 {
  font-size: 14px;
  margin: auto;
}

.containerDad {
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  border-radius: 20px;
}

.containerDad > div:first-child {
  border-radius: 20px 20px 0 0;
  border: none;
}

.containerDad > div:first-child {
  border-radius: 10px 10px 0 0;
  border: none;
}

.radius {
  border-radius: 20px !important;
}

.containerDad > div:last-child {
  border-radius: 0 0 10px 10px;
  border: none;
}

.containerList div {
  display: flex;
  align-items: center;
  height: 50px;
}

.containerList:nth-child(odd) {
  background-color: #fff;
  background-color: #357eb8;
  color: #fff;
  padding: 10px 0;
}

.containerList:nth-child(even) {
  background-color: #6aacdf;
  background-color: #175d93;
  color: #fff;
  padding: 10px 0;
}

.null {
  color: #fff;
  font-size: 20px;
  padding: 20px 0;
  font-weight: 700;
}

.btn-icons img {
  width: 30px;
}

.flex div {
  display: flex;
  justify-content: space-between;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  width: auto;
  padding: 5px;
}

.btn_default:hover {
  background-color: #e0a638;
}

.pay {
  width: 515px;
  margin: 25px auto;
}

.payColor {
  background: #fff;
  color: #000;
}
</style>
