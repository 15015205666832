<template>
  <div>
    <!-- Button trigger modal -->
    <!-- <button
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#staticBackdrop"
    >
      Launch static backdrop modal
    </button> -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="detalhesModal"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content backgroundColorDefault">
          <div class="modal-header headerModal">
            <h5 class="modal-title" id="staticBackdropLabel">
              Detalhes da Conta
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <h3>Código de Barras</h3>
              <p>{{ detalhes.codigo }}</p>
            </div>
            <div>
              <h3>Instituição emissora</h3>
              <p>{{ detalhes.emissor }}</p>

              <h3>Nome</h3>
              <p> {{ detalhes.beneficiario }} </p>
            </div>
            <div>
              <h3>Razão Social</h3>
              <h3>CPF/CNPJ</h3>
              <p>1111111111111111111111</p>
            </div>

            <div class="mt-5">
              <h3>Dados do Pagador</h3>
              <h3>Nome</h3>
              <p> {{ detalhes.pagador }} </p>
            </div>
            <div>
              <h3>CPF/CNPJ</h3>
              <p>1111111111111111111111</p>
            </div>
            <div>
              <h3>Valor do Documento</h3>
              <p> {{ detalhes.valor }} </p>
            </div>

            <div class="container">
              <div class="row">
                <div class="col-4">
                  <h3>Desconto</h3>
                  <p>R$ xxxx</p>
                </div>
                <div class="col-4">
                  <h3>Juros / Mora</h3>
                  <p>R$ xxxx</p>
                </div>
                <div class="col-4">
                  <h3>Multa</h3>
                  <p>R$ xxxx</p>
                </div>
              </div>
            </div>

            <div>
              <h3>Total de encargos</h3>
               <p> {{ detalhes.valor }} </p>
            </div>
            <div>
              <h3>Total Final</h3>
             <p> {{ detalhes.valor }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['detalhes']
}
</script>

<style scoped>
.headerModal {
  border: 0 !important;
}

.backgroundColorDefault {
  background-color: #308cd3;
  background-color: #305ed3;
  background-color: #fff;
  color: #308cd3;
  border-radius: 20px;
}
h3 {
  font-weight: 700;
  font-size: 15px;
}

p {
  margin-left: 10px;
}
</style>
