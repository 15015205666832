<template>
	<div class="instrucao w-100" :style="`height:${100}vh;`">
		<div class="topo_box d-flex align-items-center justify-content-center">
			<h4 class="mb-0">
				Lista de instruções
				<i
					class="fas fa-play-circle ml-2 box_play"
					@click.prevent="showVideo()"
					v-if="link"
				></i>
			</h4>
		</div>
		<div class="box_corpo" :style="`max-height:${minHeight}px;`">
			<div class="col-md-12 my-4" v-for="(item,index) in instructions" :key="index">
				<div
					class="box d-flex flex-column align-items-center py-2 justify-content-center"
					:class="item.spotlight ? 'boxDestaque' : ''"
				>
					<div class="col-md-10 mx-auto" :id="`boxImg${index}`">
						<img :src="getImg(item.image)" alt class="imageBox" @error="removeBox(index)" />
					</div>
					<div class="img-box px-1">
						<h4 class="mb-0 text-center py-2">{{item.title}}</h4>
						<p class="mb-0">{{item.description}}</p>
					</div>
					<div class="position-absolute box_absoluto d-flex align-items-center justify-content-center">
						<span>{{item.sequence}}</span>
					</div>
				</div>
			</div>
		</div>
		<component :is="modalCp" v-if="link !=''" :link="link" @limpaIframe="limpaIframe" />
	</div>
</template>

<script>
	import modalIframe from "../_modalIframe";
	export default {
		props: ["instructions", "link"],
		components: { modalIframe },
		data() {
			return {
				minHeight: window.innerHeight - 140,
				modalCp: "",
			};
		},
		mounted() {
			var self = this;
			this.$nextTick(() => {
				window.addEventListener("resize", this.getWindowHeight);
			});
		},
		methods: {
			removeBox(index) {
				$(`#boxImg${index}`).css("display", "none");
			},
			showVideo() {
				this.modalCp = "modalIframe";
				this.$nextTick(() => {
					$("#modalIframe").modal("show");
				});
			},
			limpaIframe() {
				this.modalCp = "";
			},
			getWindowHeight() {
				var h = document.documentElement.clientHeight;
				this.minHeight = h - 140;
			},
		},
	};
</script>

<style scoped>
.imageBox {
	max-height: 110px;
	border-radius: 20px;
}
i {
	cursor: pointer;
}
.instrucao {
	background-color: #357eb8;
	box-shadow: -2px 6px 8px 2px rgba(68, 68, 68, 0.3);
}
.topo_box {
	background-color: #357eb8;
	height: 60px;

	box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
.topo_box h4 {
	font-size: 16px;
	color: #ffffff;
}
img {
	width: 100%;
}
.box {
	background-color: #ffffff;
	min-height: 210px;
	border-radius: 20px;
	box-shadow: 0px 8px 8px 0 rgba(68, 68, 68, 0.3);
}
.box p {
	font-size: 10px;
	text-align: center;
	color: #868686;
}
.box h4 {
	font-size: 13px;
	color: #2474b2;
}
.box_absoluto {
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	width: 40px;
	background-color: #357eb8;
	top: -15px;
	text-align: center;
	height: 40px;
	border-radius: 50%;
	border: 3px solid #ffffff;
}
.box_absoluto span {
	font-weight: 700;
	color: #ffffff;
}
.box_corpo {
	overflow-x: hidden;
	/* max-height: 600px; */
	overflow-y: scroll;
}
.boxDestaque {
	background-color: #e0a638;
	transition: all 0.2s linear;
}
.boxDestaque p {
	color: #ffffff;
	transition: all 0.2s linear;
}
.boxDestaque h4 {
	color: #ffffff;
	transition: all 0.2s linear;
}
.boxDestaque .box_absoluto {
	background-color: #e0a638;
	transition: all 0.2s linear;
}
</style>
