<template>
  <div>
    <div class="containerInstallment">
      <div class="container">
        <div class="row">
          <h1>Detran</h1>
          <div class="flex col-12 p-0 mt-2"></div>
        </div>
      </div>
      <div class="container">
        <div class="row flex-column">
          <div class="col-4 p-0">
            <div class="form-group mt-4 sizeContainer d-flex flex-column">
              <label for="InputCPF"> Selecione o Estado do Detran </label>
              <select name="" id="" class="form-control" v-model="codigo">
                <option
                  v-for="label in labels"
                  :value="label.codigo"
                  :key="label.codigo"
                  >{{ label.nome }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-4 p-0" v-for="input in inputs" :key="input.codigo">
            <div class="form-group mt-4 sizeContainer">
              <label for="InputNumber"> {{ input.descricao }} : <b>*</b></label>
              <input
                v-if="input.valores.length < 1"
                type="text"
                name=""
                id=""
                class="form-control"
                v-model="fone"
              />
              <select v-else name="" id="" class="form-control">
                <option
                  v-for="valores in input.valores"
                  :key="valores.codigo"
                  :value="valores.codigo"
                  >{{ valores.conteudo }}</option
                >
              </select>
            </div>
          </div>
          <!-- <div class="col-4 p-0 pr-0">
            <div class="form-group mt-4 sizeContainer">
              <label for="renavam">Renavam:</label>
              <input type="text" class="form-control" id="renavam" />
            </div>
          </div> -->
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="formBtn col-12 p-0">
            <!-- <button class="btn_default mr-4">Adicionar Debito</button> -->
            <button class="btn_default" @click="validetion" v-if="codigo > 0">
              Seguinte
            </button>
          </div>
        </div>
      </div>
      <!-- <Modal /> -->
    </div>
    <!-- <List /> -->
  </div>
</template>

<script>
// import Modal from "./Modal/changeValue.vue";
import { TheMask } from "vue-the-mask";
import { API } from "@/api_financial";

export default {
  components: {
    // Modal,
    TheMask
  },
  data() {
    return {
      codigo: 0,
      labels: [],
      check: "CPF",
      CPF: "",
      CNPJ: "",
      fone: null,
      requerid: false,
      inputs: []
    };
  },
  mounted() {
    this.getApiMounted();
  },
  methods: {
    validetion() {
      this.componenteChangeStore("list");
    },
    getApiMounted() {
      API.get("clientes", {
        headers: {
          Authorization: `Bearer ${getters.getTokenZigNet}`,
          "Content-Type": "application/json"
        }
      })
        .then(({ data }) => {
          let { clientes } = data;
          let filterClientes = clientes.filter(item =>
            item.codigo !== 31 ? item : ""
          );
          this.labels = filterClientes;
          // this.$store.commit("usersDataChangeValue", data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    requireDetran(codigo) {
      API.get(`clientes/${codigo}`, {
        headers: {
          Authorization: `Bearer ${getters.getTokenZigNet}`,
          "Content-Type": "application/json"
        }
      })
        .then(({ data }) => {
          let { campos } = data;

          let fone = [
            ...campos,
            {
              codigo: 1312,
              descricao: "Telefone",
              valores: []
            }
          ];
          console.log(fone);
          this.inputs = fone;
        })
        .catch(err => console.log(err));
    }
  },
  watch: {
    codigo: function(res) {
      this.requireDetran(res);
    }
  }
};
</script>

<style scoped>
.containerInstallment {
  color: #fff;
}
.containerInstallment h1 {
  font-size: 20px;
  margin: 100px 0 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.checkInput {
  margin-top: 0 !important;
}

.sizeContainer {
  width: 100%;
}

.formBtn {
  display: flex;
  /* justify-content: flex-end; */
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  width: 20%;
  margin: 10px 0 5px 0px;
}

.btn_default:hover {
  background-color: #e0a638;
}

label {
  cursor: pointer;
}

b {
  color: #f38235;
}
.textRequired {
  margin: 10px 0;
  font-size: 10px;
}
</style>
