<template>
  <div class="container btns mt-4">
    <div class="row">
      <div class="col-6 text-left">
        <div v-if="pay">
          <button
            class="btn btn-icons"
             data-toggle="modal"
             data-target="#Messagemodal"
             @click="$emit('email',7)"
          >
            <img
              style="width: 20px"
              src="@/assets/image/icones/new/email.svg"
              alt="busca"
            />
          </button>
          <button class="btn btn-icons">
            <img
              style="width: 20px"
              src="@/assets/image/icones/new/print.svg"
              alt="busca"
            />
          </button>
        </div>
        <button v-else class="btn btn_default" @click="componenteChange">
            Adicionar Conta
        </button>
      </div>
      <div class="col-6 text-center mt-2">
        <h4>Total: {{ valueConvert(soma) }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pay","soma"],

  data() {
    return {};
  },
  methods: {
    componenteChange() {
      // this.$store.commit("changeComponent", "Users");

        $("#addCodigo").modal("show");
      // console.log(this.$store.getters.componentCurrentValue);
    },
  },
};
</script>

<style scoped>
.btns div {
  /* display: flex; */
  /* align-items: center; */
  color: #fff;
}

.btns div h4 {
  font-size: 15px;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  width: auto;
  padding: 5px;
  /* margin: 10px 0 5px 5px; */
}

.btn_default:hover {
  background-color: #e0a638;
}
</style>