<template>
  <div class="box_iframe">
    <div class="row align-items-center ">
      <div class="borderBt">
        <div class="col-md-auto pl-0 pb-3">
          <p class="mb-0 topo_p">
            {{ categoryName }} / <strong>{{ service.name }}</strong>
          </p>
        </div>
      </div>
      <div
        class="col-md-12 py-4 px-0 d-flex justify-content-between align-items-center containertop"
      >
        <strong>{{ service.name }}</strong>
        <div>
          <h4>
            Valor:
            <strong> {{ $options.filters.currency(service.value) }} </strong>
          </h4>
        </div>
      </div>
      <div
        class="col-md-12 py-1 pr-1 d-flex justify-content-between align-items-center containerImp"
      >
        <p class="m-0 col-4">Fila de impressão</p>
        <div class="d-flex align-items-center justify-content-end col-4">
          <icons_custom
            icone="impressoraNew"
            bg="#868686"
            wi="25px"
            hei="25px"
            class="mr-1 icon"
          />
          <p class="mb-0 p_impressoes mr-3">
            <strong class="boxNumber">
              {{ totalPages > 9 ? totalPages : `0${totalPages}` }} </strong
            >impressões
          </p>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end pr-0">
          <button
            v-if="$store.getters.getUser.profile == 3"
            :disabled="disabled || getLoadService"
            @click.prevent="sendPedido()"
            class="btn_default py-2 col-md-6 d-flex align-items-center justify-content-center"
          >
            <half-circle-spinner
              v-if="load || getLoadService"
              :animation-duration="1000"
              :size="30"
              color="#fff"
            />
            <p v-else class="my-2">Próximo</p>
          </button>
        </div>
      </div>
      <div class="col-md-12 pt-3">
        <div class="wrap">
          <iframe
            v-if="homologa"
            class="frame"
            id="iframesTest"
            ref="iframeRef"
            :style="escale"
            :src="service.link"
            :sandbox="
              visible
                ? 'allow-scripts allow-same-origin allow-forms'
                : 'allow-scripts allow-same-origin allow-forms'
            "
          ></iframe>
          <div class="col-md-12">
            <div class="owl-carousel">
              <div
                class="col-md-12"
                style="margin-bottom: 20px"
                v-for="(item, index) in instructions"
                :key="index"
              >
                <div
                  div
                  class="box d-flex flex-column align-items-center py-3"
                  :class="item.spotlight ? 'boxDestaque' : ''"
                >
                  <div class="col-md-9 px-0 mx-auto imageBox">
                    <img :src="getImg(item.image)" @error="errorImage2" />
                  </div>
                  <div class="col-md-10 mx-auto img-box">
                    <h4 class="mb-0 text-center py-2">{{ item.title }}</h4>
                    <p class="mb-0">{{ item.description }}</p>
                  </div>
                  <div
                    class="position-absolute box_absoluto d-flex align-items-center justify-content-center"
                  >
                    <span>{{ item.sequence }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 ml-auto py-3 text-left px-0">
            <button
              v-if="$store.getters.getUser.profile != 1"
              class="link_btn p-2 d-flex align-items-center justify-content-center"
              @click.prevent="openModal()"
            >
              <img
                class="m-2"
                src="@/assets/image/icones/warner.png"
                alt="warner"
              />
              <div>Reportar problema no <strong> Serviço.</strong></div>
            </button>
          </div>
          <div
            class="col-md-8 mr-auto py-3 text-center px-0 d-flex align-items-center"
            v-if="visible"
          >
            <a href="#" class="link_a" @click.prevent="openPopup(service.link)">
              Algum problema para abrir?
              <strong>Tente novamente.</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icons_custom from "@/components/utils/_Icon";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: { icons_custom, HalfCircleSpinner },
  props: [
    "service",
    "categoryName",
    "score",
    "totalPages",
    "list",
    "instructions"
  ],
  data() {
    return {
      load: false,
      escale: "",
      disabled: false,
      visible: false,
      homologa: true
    };
  },
  computed: {
    ...mapGetters(["getOrderServiceId", "getLoadService"])
  },
  mounted() {
    this.homologaShow();
    if (!this.service.hasPopup) {
      this.openPopup(this.service.link);
      this.visible = true;
    } else {
      this.visible = false;
    }
    this.escale = this.calcEscale();

    $(".owl-carousel").owlCarousel({
      loop: false,
      nav: true,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left position-absolute icon_owl_left icon_owl" aria-hidden="true" ></i>',
        '<i class="fa fa-angle-right position-absolute  icon_owl_right icon_owl" aria-hidden="true" ></i>'
      ],
      items: 3
    });
  },
  methods: {
    ...mapActions(["CheckFinishedOrder", "sendPedidos"]),
    ...mapMutations(["set_pedido"]),
    homologaShow() {
      let urlApi = process.env.VUE_APP_ROOT_API;
      if (urlApi === "//apiteste.pontofacil.fasters.com.br")
        this.homologa = false;
    },
    openModal() {
      $("#modalSolicitarServicoEdit").modal("show");
    },
    calcEscale() {
      const scale = $(".wrap").width() / 1290;
      return `transform: scale(${scale});`;
    },
    openPopup(link) {
      const h = 450;
      const w = 900;
      const left = screen.width / 2 - w / 2;
      const top = screen.height / 2 - h / 2;

      window.open(
        `${link}`,
        "winname",
        `directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,top=${top},left=${left},width=${w},height=${h}`
      );
      window.close();
    },
    async sendOnlyRequest(jsonParams) {
      try {
        const result = await this.sendPedidos(jsonParams);
        this.load = false;
        this.set_pedido(result.data.result);
        this.$nextTick(() => {
          this.$router.push({ path: "/finalizar-servico" });
        });
      } catch (error) {
        this.load = false;
        this.toast(error.response.data.error.message, this.$toast.error);
        this.$store.commit("changeOrderId", "");
        setTimeout(() => {
          this.$store.commit("set_pedido", "");
          this.$router.push("/pedidos");
        }, 2000)
        // this.toast("Ops algo deu errado", this.$toast.error);
      }
    },
    async sendPedido() {
      this.disabled = this.load = true;
      const { id } = this.$store.state.pedidos.pedidos;
      const jsonParams = {
        orderServiceId: this.getOrderServiceId,
        listPrint: this.list,
        quantity: ""
      };

      if (this.$store.state.pedidos.pedidos != "") {
        try {
          const resultValues = await this.sendPedidos(jsonParams);
          this.set_pedido(resultValues.data.result);
          this.$nextTick(() => {
            this.$router.push({ path: "/finalizar-servico" });
            this.disabled = this.load = false;
          });
          return;
        } catch (error) {
          this.disabled = false;
          this.load = false;
          if (error.response.data) {
            this.toast(error.response.data.error.message, this.$toast.error);
            this.$store.commit("changeOrderId", "");
            setTimeout(() => {
              this.$store.commit("set_pedido", "");
              this.$router.push("/pedidos");
            }, 2000);
            return
          }
          this.toast("Ops algo deu errado", this.$toast.error);
          return;
        }
      }
      this.sendOnlyRequest(jsonParams);
    }
  }
};
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 50vh;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1290px;
  height: 650px;
  border: 0;
  /* transform: scale(0.7); */
  transform-origin: 0 0;
}

.icon {
  background-color: #fefefe;
  transition: all 0.2s linear;
}
.icon:hover {
  background-color: #e0a638;
  transition: all 0.2s linear;
}
.borderBt {
  border-bottom: 1px solid #98c4e675;
  width: 100%;
}
.topo_p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #98c4e6;
}

.topo_p strong {
  color: #ffffff;
}

.containertop strong {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.containertop h4 {
  color: #98c4e6;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

.containertop h4 strong {
  font-size: 20px;
}

.containerImp {
  background: #206aad;
  border-radius: 8px;
}
.containerImp p {
  font-size: 14px !important;
  color: #fff;
}

.boxNumber {
  background: #2474b2;
  border-radius: 6px;
  margin: 10px;
  padding: 11px 13px;
  font-weight: 600;
  font-size: 16px;
}

.btn_default {
  background-color: #f38235;
  border: none;
}

.btn_default:hover {
  background-color: #e0a638;
}

.p_impressoes {
  color: #ffffff;
}

.emoji img {
  width: 38px;
  margin-right: 10px;
  height: 38px;
}
.emoji p {
  font-size: 10px;
  color: #ffffff;
}
.emoji {
  opacity: 60%;
}
.link_a {
  color: #ffffff;
}
.link_btn {
  border: 1px solid #98c4e6;
  border-radius: 22px;
  background: none;
  color: #fff;
  font-size: 12px;
  height: 40px;
  width: 170px;
  outline: none;
  transition: 0.2s ease-in-out;
}

.link_btn:hover {
  background: rgba(152, 196, 230, 1);
}
.link_btn img {
  width: 20px;
}

.imageBox {
  height: 180px;
  overflow: hidden;
  border-radius: 20px;
}
.owl-dots {
  text-align: center;
}
.btn_default {
  background-color: #f38235;
  border: none;
}
.btn_default:hover {
  background-color: #e0a638;
}
.box_play:hover {
  color: #f38235;
  transition: all 0.2s linear;
}
.servico {
  background-color: #357eb8;
}
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.box {
  background-color: #ffffff;
  min-height: 318px;
  border-radius: 20px;
  box-shadow: 0px 8px 8px 0 rgba(68, 68, 68, 0.3);
}
.box p {
  font-size: 10px;
  text-align: center;
  color: #868686;
}
h4 {
  font-size: 13px;
  color: #2474b2;
}
.box_absoluto {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 40px;
  background-color: #357eb8;
  bottom: -20px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #ffffff;
}
.box_absoluto span {
  font-weight: 700;
  color: #ffffff;
}
.boxDestaque {
  background-color: #e0a638;
  transition: all 0.2s linear;
}
.boxDestaque p {
  color: #ffffff;
  transition: all 0.2s linear;
}
.boxDestaque h4 {
  color: #ffffff;
  transition: all 0.2s linear;
}
.boxDestaque .box_absoluto {
  background-color: #e0a638;
  transition: all 0.2s linear;
}
</style>
