<template>
  <div>
    <div
      class="modal fade"
      id="Messagemodal"
      
      tabindex="-1"
      aria-hidden="true"
    >
    <!-- <div
      class="modal fade"
      id="Messagemodal"
      data-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
    > -->
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content backgroundColor">
          <div class="modal-body rounded border-0">
            <div
              class="container d-flex align-items-center justify-content-center flex-column "
            >
              <div class="row m-3" >
                <h3 class="text-center m-0">
                  {{ conditions }}
                </h3>

              </div>
                <button class="btn_default bg-danger" @click="cancelOperator">Cancelar Operação</button>


              <!-- <div
                class="row m-3 d-flex flex-column"
                v-else-if="conditions === 2"
              >
                <h3 class="text-center m-0">Quantos Cartões?</h3>
                <div class="flex justify-content-around">
                  <select class="selects">
                    <option
                      v-for="(item, index) in theAmount"
                      :key="index"
                      :value="item.theAmountCard"
                    >
                      {{ item.theAmountCard }}
                    </option>
                  </select>

                  <button class="btn_default pl-3 pr-3 m-4">ok</button>
                </div>
              </div>

              <div
                class="row m-3 d-flex flex-column"
                v-else-if="conditions === 3"
              >
                <h3 class="text-center m-0">Valor: ( 1 / 2)</h3>
                <h3 class="text-center m-0">Valor: ( 2 / 2)</h3>
                <div class="flex justify-content-around">
                  <input type="text" class="selects" />
                  <button class="btn_default pl-3 pr-3 m-4">ok</button>
                </div>
              </div>

              <div
                class="row m-3 d-flex flex-column"
                v-else-if="conditions === 4"
              >
                <h3 class="text-center m-0">Valor: ( 1 / 2)</h3>
                <div class="flex justify-content-around">
                  <input type="text" class="selects" />
                  <button
                    class="btn_default pl-3 pr-3 m-4"
                    @click="componenteChange"
                  >
                    ok
                  </button>
                </div>
              </div>

              <div
                class="row m-3 d-flex flex-column"
                v-else-if="conditions === 6"
              >
                <h3 class="text-center m-0">
                  Se o Cliente parcelou sua conta, ofereça a proteção premiada
                </h3>
                <div class="flex justify-content-around">
                  <button
                    class="btn_default pl-3 pr-3 m-4"
                    @click="componenteChange"
                  >
                    Ir para o serviço
                  </button>
                  <button
                    class="btn_default pl-3 pr-3 m-4"
                    @click="componenteChange"
                  >
                    Voltar para home
                  </button>
                </div>
              </div>

              <div
                class="row m-3 d-flex flex-column"
                v-else-if="conditions === 7"
              >
                <h3 class="text-center m-0">
                  Email para envio do comprovante:
                </h3>
                <div class="flex justify-content-around">
                  <form action="">
                    <input type="email" class="inputs" required />

                    <input 
                      type="submit"
                      class="btn_default pl-3 pr-3 ml-3 mt-4"
                      value="Enviar"
                    />
                      
                  </form>
                </div>
              </div> -->

              <!-- <div class="d-flex justify-content-between">
                <button
                  type="button"
                  class="btn btn_default pl-4 pr-4"
                  data-dismiss="modal"
                >
                  Não! Voltar
                </button>
                <button
                  type="button"
                  class="btn btn_default pl-5 pr-5"
                  data-dismiss="modal"
                >
                  sim
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['conditions'],
  data() {
    return {
      theAmount: [
        {
          theAmountCard: "1",
        },
        {
          theAmountCard: "2",
        },
        {
          theAmountCard: "3",
        },
        {
          theAmountCard: "4",
        },
      ],
    };
  },
  methods: {
    componenteChange() {
      
      $('#Messagemodal').modal('hide')
      
      this.$router.push('/')
      
      this.$store.commit("changeComponent", "List");

    },
    cancelOperator() {
      this.$store.dispatch('cancelPinPadPay')
    }
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #2474b2;
  color: #fff;
  border-radius: 20px;
}

h3 {
  color: #fff;
  font-size: 15px;
}

.selects {
  width: 80px !important;
  padding: 5px 10px;
  margin: 0 10px 0 0;
}

.btn_default {
  background-color: #f38235;
  height: 40px;
  border: none;
  width: auto;
  padding: 5px;
  /* margin: 10px 0 5px 5px; */
}

.btn_default:hover {
  background-color: #e0a638;
}

.inputs {
  height: 40px;
  border: none;
  flex: 1;
  width: 305px;
  padding: 5px;
}
</style>